import React from 'react';
import { overlayProps } from '../../../types/overlayModel';
import RankingBar from './rankingBar/RankingBar';
import MapNavigation from '../mapNavigation/MapNavigation';

import './MapOverlay.scss';

const MapOverlay: React.FC<overlayProps> = (props) => {
    const { fly, map } = props;

    return (
        <>
            <MapNavigation fly={fly} map={map} />

            <RankingBar fly={fly} />
        </>
    );
};

export default MapOverlay;
