import React, { FC, useContext, useEffect } from 'react';
import mapboxgl from '!mapbox-gl';

import './Popup.scss';
import { CityModel, PostModel } from '../../../../types/mapModel';
import { DEFAULT_IMAGE_URL } from '../../../../utils/constants/posts';
import { AppContext } from '../../../../store/AppContextProvider';

interface DefaultPopupConfigurationModel {
    offset: [number, number];
    className: string;
    maxWidth: string;
}

const DEFAULT_POPUP_CONFIGURATION: DefaultPopupConfigurationModel = {
    offset: [0, -15],
    className: 'map__popup',
    maxWidth: 'none',
};

const popup = (map: mapboxgl.Map, cities: CityModel[]) => {
    let lastPopup: mapboxgl.Popup[] = [];
    let currentMediaHTMLElement: HTMLVideoElement | HTMLImageElement | undefined;

    const clearPopup = () => {
        // // Close previous popup if exists
        if (lastPopup.length > 0) {
            lastPopup.forEach((lp) => lp.remove());
            lastPopup = [];
        }
    };

    const fetchImage = async (url: string) => {
        const res = await fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
        const imageBlob = await res.blob();
        let imageObjectURL = URL.createObjectURL(imageBlob);
        if (!imageObjectURL) imageObjectURL = DEFAULT_IMAGE_URL;
        return imageObjectURL;
    };

    if (map) {
        map.on('click', () => {
            clearPopup();
        });

        map.on('mouseleave', ['cityMarkers-points', 'cityMarkers-points-one-post-per-city'], () => {
            clearPopup();
        });

        map.on('mouseenter', ['cityMarkers-points', 'cityMarkers-points-one-post-per-city'], async (e) => {
            // Get layer data by marker
            const features = map.queryRenderedFeatures(e.point, {
                layers: ['cityMarkers-points', 'cityMarkers-points-one-post-per-city'], // replace with your layer name
            });

            if (!features.length) {
                return;
            }

            // Get Data
            const feature = features[0];
            const coordinates = feature.geometry.coordinates;

            const cityId = feature.properties?.cityId;
            const postId = feature.properties?.id;

            let currentPost: PostModel | undefined;
            const city: CityModel | undefined = cities.find((city: CityModel) => city.id === cityId);
            if (city?.posts) {
                currentPost = city.posts.find((post) => post.properties.id === postId);
            }

            // Set curson pointer
            map.getCanvas().style.cursor = 'pointer';

            if (currentPost?.properties?.attachedMedia && currentPost?.properties?.attachedMedia.length > 0) {
                // Functions START

                const createPopup = (media: HTMLElement, isImage: boolean) => {
                    window.handleImgLoad = handleImgLoad;

                    const popup = document.createElement('div'); //create popup element & add class
                    popup.classList.add('popup');

                    const popupContainer = document.createElement('div'); //create popup container element & add class, append it to the popup div
                    popupContainer.classList.add('popup__content-container');
                    popup.append(popupContainer);

                    popupContainer.append(media);

                    // set handleImgLoad on onload event
                    let html = popup.outerHTML;
                    let splitPattern = isImage ? '<img' : '<video';
                    let loadEventName = isImage ? 'onload' : 'onloadeddata';
                    html = `${
                        html.split(splitPattern)[0]
                    } ${splitPattern} ${loadEventName}="window.handleImgLoad(this)" ${html.split(splitPattern)[1]}`;

                    return `${html}`; //Return a string bcs the mapbox setHTML prop asks for a string
                };

                const createPopupMedia = async (
                    attachedMedia: { mediaLink?: string | undefined }[] | undefined,
                    socialMedia: string | undefined,
                ) => {
                    let attachedMediaUrl: string = '';
                    let isVideo: boolean = false;
                    let isImage: boolean = false;

                    if (attachedMedia && attachedMedia[0] && attachedMedia[0].mediaLink) {
                        isVideo = attachedMedia[0].mediaLink.includes('.mp4');
                        isImage =
                            attachedMedia[0].mediaLink.includes('.jpg') ||
                            attachedMedia[0].mediaLink.includes('.jpeg') ||
                            attachedMedia[0].mediaLink.includes('.png') ||
                            attachedMedia[0].mediaLink.includes('.webp');
                        if (socialMedia === 'tiktok' || socialMedia === 'twitter') {
                            attachedMediaUrl = attachedMedia[0].mediaLink;
                        } else {
                            attachedMediaUrl = await fetchImage(
                                `https://cors-anywhere-gotmilk.herokuapp.com/${attachedMedia[0].mediaLink}?not-from-cache-please`,
                            );
                        }
                    }

                    let mediaHTMLElement: HTMLVideoElement | HTMLImageElement | undefined;

                    if (socialMedia === 'tiktok' || isVideo) {
                        mediaHTMLElement = document.createElement('video');
                        mediaHTMLElement.classList.add('popup__media', 'popup__media--video');
                        mediaHTMLElement.controls = true;
                        mediaHTMLElement.autoplay = true;
                        mediaHTMLElement.muted = true;
                        mediaHTMLElement.src = attachedMediaUrl;
                    } else if (isImage) {
                        mediaHTMLElement = document.createElement('img');
                        mediaHTMLElement.classList.add('popup__media', 'popup__media--image');
                        mediaHTMLElement.crossOrigin = 'anonymous';
                        mediaHTMLElement.src = attachedMediaUrl;
                        // handle image error
                        mediaHTMLElement.onerror = (ev) => {
                            if (currentMediaHTMLElement) {
                                currentMediaHTMLElement.src = DEFAULT_IMAGE_URL; // use stock image
                                // reset popup with stock image
                                clearPopup();
                                const popup = new mapboxgl.Popup(DEFAULT_POPUP_CONFIGURATION)
                                    .setLngLat(coordinates)
                                    .setHTML(createPopup(currentMediaHTMLElement, isImage))
                                    .addTo(map);
                                lastPopup.push(popup);
                            }
                        };
                    }
                    if (mediaHTMLElement) {
                        currentMediaHTMLElement = mediaHTMLElement;
                        return createPopup(mediaHTMLElement, isImage);
                    }
                    return '';
                };

                const handleImgLoad = (htmlMediaElement: any) => {
                    htmlMediaElement.style = `aspect-ratio: ${
                        (htmlMediaElement.naturalWidth ?? htmlMediaElement.videoWidth) /
                        (htmlMediaElement.naturalHeight ?? htmlMediaElement.videoHeight)
                    }`;

                    htmlMediaElement.parentElement.style = `aspect-ratio: ${
                        (htmlMediaElement.naturalWidth ?? htmlMediaElement.videoWidth) /
                        (htmlMediaElement.naturalHeight ?? htmlMediaElement.videoHeight)
                    }`;
                };
                // Functions END

                // clear popup before synchronous call
                clearPopup();

                // Create and store popup
                const popup = new mapboxgl.Popup(DEFAULT_POPUP_CONFIGURATION)
                    .setLngLat(coordinates)
                    .setHTML(
                        await createPopupMedia(
                            currentPost?.properties?.attachedMedia,
                            currentPost?.properties.socialMedia,
                        ),
                    )
                    .addTo(map);

                // clear popup after synchronous call
                clearPopup();

                // add popup
                lastPopup.push(popup);
            }
        });
    }

    return <></>;
};

export default popup;
