interface InitialMapConfigModel {
    style: string;
    center: [number, number];
    zoom: number;
    minZoom: number;
    maxBounds: [[number, number], [number, number]];
    projection: { name: ProjectionTypes };
}

enum ProjectionTypes {
    equirectangular = 'equirectangular',
    albers = 'albers',
    equalEarth = 'equalEarth',
    lambertConformalConic = 'lambertConformalConic',
    mercator = 'mercator',
    naturalEarth = 'naturalEarth',
    winkelTripel = 'winkelTripel',
    globe = 'globe',
}

export const INITIAL_MAP_CONFIG_DESKTOP: InitialMapConfigModel = {
    style: 'mapbox://styles/unitedcollective/clestmoe200a001oavr7js8zc',
    center: [-120.34768740239377, 36.73019480895978],
    zoom: 5.551275980045801,
    minZoom: 5.551275980045801,
    maxBounds: [
        [-141.3000882334653, 26.396828570500077],
        [-101.3000882334569, 46.48038939988402],
    ],
    projection: { name: ProjectionTypes.equirectangular },
};

export const INITIAL_MAP_CONFIG_MOBILE: InitialMapConfigModel = {
    style: 'mapbox://styles/unitedcollective/clestmoe200a001oavr7js8zc',
    center: [-119.3067713820377, 36.115435033544536],
    zoom: 4.555491180154891,
    minZoom: 4.555491180154891,
    maxBounds: [
        [-125.0247499944951, 23.139906374107216],
        [-113.36316299522885, 46.69525853640294],
    ],
    projection: { name: ProjectionTypes.equirectangular },
};

export const MARKER_ZOOM_DESKTOP = 14.825204483626388;

export const MARKER_ZOOM_MOBILE = 13.193739308697747;
