import mapboxgl from '!mapbox-gl';
import React from 'react';

import { CityModel } from '../../../../types/mapModel';
import { createGrayDot } from './createPulsingPoint';

const backgroundCityPoints = (map: mapboxgl.Map, cities: CityModel[]) => {
    const pulsingDot = createGrayDot(map, 0.2, 50);
    map.on('load', () => {
        map.addImage('pulsing-dot-city', pulsingDot, { pixelRatio: 2 });
        let backgroundPoints: any[] = [];

        cities.forEach((c) => backgroundPoints.push(...c.backgroundPoints));

        let dataSource = {
            type: 'FeatureCollection',
            features: backgroundPoints,
        };

        map.addSource('cityPoints-source', {
            type: 'geojson',
            data: dataSource,
        });

        map.addLayer({
            id: 'cityPoints',
            type: 'symbol',
            source: 'cityPoints-source',
            minzoom: 7,
            layout: {
                'icon-image': 'pulsing-dot-city',
                'icon-ignore-placement': true,
            },
            // paint: {
            //     'circle-color': '#FCB900',
            //     'circle-radius': 7,
            //     'circle-opacity': 0.2,
            // },
        });
    });
};

export default backgroundCityPoints;
