import React, { FC, useContext, useEffect, useState } from 'react';
import { CitiesRankingModel } from '../../../../types/mapModel';
import { AppContext } from '../../../../store/AppContextProvider';
import './RankingBar.scss';

import Marquee from 'react-fast-marquee';

const RankingBar: FC<any> = (props: any) => {
    const { fly }: { fly: Function } = props;
    const { rankedCities, clearCurrentPostAndCity, setCurrentCity } = useContext(AppContext);
    const [cities, setCities] = useState<CitiesRankingModel[]>([]);

    useEffect(() => {
        setCities(rankedCities);
    }, [rankedCities]);

    const navigateToCity = (cityId: string, coordinates: [number, number]) => {
        clearCurrentPostAndCity();
        setCurrentCity(cityId);
        fly(coordinates[1], coordinates[0]);
    };

    if (cities.length > 0)
        return (
            <section className="ranking-bar">
                <div className="ranking-bar__wrapper">
                    <div className="ranking-bar__title-wrapper">
                        <p className="ranking-bar__title">CITIES GETTING REAL</p>
                    </div>
                    <div className="ranking-bar__items-wrapper">
                        <div className="ranking-bar__ticker ticker">
                            <Marquee gradient={false} speed={70}>
                                {cities.slice(0).map((city, index) => {
                                    return (
                                        <div
                                            key={`ranking-bar-city-${index}`}
                                            className="ticker__city-item city-item"
                                            onClick={() => {
                                                navigateToCity(city.cityId, city.coordinates);
                                            }}
                                        >
                                            <p className="city-item__ranking">{`#${city.ranking}`}</p>
                                            <p className="city-item__name">{city.name}</p>
                                        </div>
                                    );
                                })}
                            </Marquee>
                        </div>
                    </div>
                </div>
            </section>
        );
    return <></>;
};

export default RankingBar;
