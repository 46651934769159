import mapboxgl from '!mapbox-gl';
import { CityModel, PostModel } from '../../../types/mapModel';
import { createPulsingPoint } from './bgPoints/createPulsingPoint';

const cityMarkers = (map: mapboxgl.Map, cities: CityModel[], openSidebar: Function) => {
    const pulsingDot = createPulsingPoint(map, 0.3, 90);
    let allCityPosts: PostModel[] = [];
    let onePostPerCity: PostModel[] = [];

    cities.forEach((city) => {
        let cityPosts: PostModel[] = [];

        if (city.posts && city.posts.length > 0) city.posts.forEach((post) => cityPosts.push(post));

        if (cityPosts.length > 0) {
            let randomPostIndex = Math.floor(Math.random() * cityPosts.length);
            onePostPerCity.push(cityPosts[randomPostIndex]);

            allCityPosts.push(...cityPosts);
        }
    });

    if (allCityPosts) {
        map.on('load', () => {
            map.addImage('marker-point', pulsingDot, { pixelRatio: 2 });

            map.addSource('cityMarkers', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: allCityPosts,
                },
            });

            map.addLayer({
                id: 'cityMarkers-points',
                type: 'symbol',
                source: 'cityMarkers',
                minzoom: 7,
                layout: {
                    'icon-image': 'marker-point',
                    'icon-ignore-placement': true,
                },
                filter: ['!', ['has', 'point_count']],
            });

            // Add one post per city
            map.addSource('cityMarkers-one-post-per-city', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: onePostPerCity,
                    animate: true,
                },
            });

            map.addLayer({
                id: 'cityMarkers-points-one-post-per-city',
                type: 'symbol',
                source: 'cityMarkers-one-post-per-city',
                maxzoom: 7,
                layout: {
                    'icon-image': 'marker-point',
                },
                filter: ['!', ['has', 'point_count']],
            });
        });

        map.on('click', ['cityMarkers-points', 'cityMarkers-points-one-post-per-city'], (e) => {
            const features = map.queryRenderedFeatures(e.point, {
                layers: ['cityMarkers-points', 'cityMarkers-points-one-post-per-city'],
            });
            if (!features.length) return;

            if (window.dataLayer)
                window.dataLayer.push({
                    event: 'contentClick',
                });

            openSidebar(features[0]);
        });
    }
};

export default cityMarkers;
