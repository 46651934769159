import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../store/AppContextProvider';
import { ButtonVariantEnum } from '../../../utils/enums/button';
import { INITIAL_MAP_CONFIG_DESKTOP } from '../../../utils/constants/map';

import Button from '../../button/Button';
import ArrowLeft from '../../../assets/icons/Arrow.inline.svg';
import ArrowRight from '../../../assets/icons/ArrowRight.inline.svg';

import './MapNavigation.scss';

interface ButtonCityData {
    cityId: string;
    cityName: string;
    cityIndex: number;
}

const MapNavigation: FC<any> = (props: any) => {
    const { fly, map } = props;
    const { cities, currentCity, setCurrentCity, clearCurrentPostAndCity, setIsSidebarOpen } = useContext(AppContext);
    const [nextCity, setNextCity] = useState<ButtonCityData>();
    const [prevCity, setPrevCity] = useState<ButtonCityData>();
    const { center, zoom } = INITIAL_MAP_CONFIG_DESKTOP;

    useEffect(() => {
        if (currentCity) {
            let currentCityIndex = cities.findIndex((c) => c.id === currentCity.id);
            const nextIndex = (currentCityIndex + 1) % cities.length;
            const prevIndex = currentCityIndex === 0 ? cities.length - 1 : currentCityIndex - 1;
            setNextCity({
                cityIndex: nextIndex,
                cityId: cities[nextIndex].id,
                cityName: cities[nextIndex].properties.name,
            });
            setPrevCity({
                cityIndex: prevIndex,
                cityId: cities[prevIndex].id,
                cityName: cities[prevIndex].properties.name,
            });
        }

        return () => {};
    }, [currentCity]);

    const navigateTo = (cityIndex: number, cityId: string) => {
        let city = cities[cityIndex];
        clearCurrentPostAndCity();
        setCurrentCity(cityId);
        fly(city.geometry?.coordinates[0], city.geometry?.coordinates[1]);
    };

    const back = () => {
        clearCurrentPostAndCity();
        setIsSidebarOpen(false);
        fly(center[0], center[1], zoom);
    };

    if (currentCity) {
        return (
            <section className="map-nav">
                <div className="map-nav__wrapper">
                    <div className="map-nav__back-button">
                        <Button
                            label="BACK"
                            type={ButtonVariantEnum.SECONDARY}
                            onClick={() => back()}
                            leftIcon={<ArrowLeft />}
                        />
                    </div>

                    <div className="map-nav__nav-buttons">
                        <Button
                            label={prevCity?.cityName}
                            type={ButtonVariantEnum.TERTIARY}
                            onClick={() => navigateTo(prevCity?.cityIndex, prevCity?.cityId)}
                            leftIcon={<ArrowLeft />}
                        />

                        <Button
                            label={nextCity?.cityName}
                            type={ButtonVariantEnum.TERTIARY}
                            onClick={() => navigateTo(nextCity?.cityIndex, nextCity?.cityId)}
                            rightIcon={<ArrowRight />}
                        />
                    </div>
                </div>
            </section>
        );
    }

    return <></>;
};

export default MapNavigation;
