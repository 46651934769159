import React from 'react';
import mapboxgl from '!mapbox-gl';
import { CityModel } from '../../../types/mapModel';

const cityLabels = (map: mapboxgl.Map, cities: CityModel[], openSidebar: Function, fly: Function) => {
    const createCityMarker = (city: string, rank: number) => {
        const cityMarker = document.createElement('div');

        if (rank < 4 && rank) {
            const cityMarkercontent = document.createElement('div');
            const cityMarkerName = document.createElement('h4');
            const cityMarkerRank = document.createElement('p');
            const cityMarkerBottomBorder = document.createElement('div');
            const cityMarkerContentWrapper = document.createElement('div');
            const cityMarkerTriangle = document.createElement('div');

            cityMarker.classList.add('city-marker');
            cityMarker.classList.add(`city-marker--rank-${rank}`);
            cityMarkerContentWrapper.classList.add('city-marker__wrapper');
            cityMarkercontent.classList.add('city-marker__marker');
            cityMarkerName.classList.add('city-marker__name');
            cityMarkerRank.classList.add('city-marker__ranking');
            cityMarkerBottomBorder.classList.add('city-marker__bottom-border');
            cityMarkerTriangle.classList.add('city-marker__triangle');

            cityMarkerName.innerText = city;
            cityMarkerRank.innerText = `#${rank} Realest`;

            cityMarkerContentWrapper.append(cityMarkerName);
            cityMarkerContentWrapper.append(cityMarkerRank);
            cityMarkercontent.append(cityMarkerContentWrapper);
            cityMarkercontent.append(cityMarkerBottomBorder);
            cityMarkercontent.append(cityMarkerTriangle);

            cityMarker.append(cityMarkercontent);
        } else {
            const cityMarkerName = document.createElement('h4');
            cityMarkerName.classList.add('city-marker__name-unranked');
            cityMarkerName.innerText = city;
            cityMarker.append(cityMarkerName);
        }

        return cityMarker;
    };

    cities.forEach((city) => {
        const { id } = city
        const { name, rank } = city.properties;
        const { coordinates, zoom } = city.geometry;

        const element = createCityMarker(name, rank);

        element.addEventListener('click', () => {
            fly(coordinates[0], coordinates[1]);
            openSidebar(id);
        });

        const marker = new mapboxgl.Marker({
            element: element,
        })
            .setLngLat(coordinates)
            .addTo(map as mapboxgl.Map);
    });
};

export default cityLabels;
