import React, { FC, useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { AppContext } from '../../../store/AppContextProvider';
import { Hashtag } from '../../../utils/types/city';
import ArrowIcon from '../../../assets/icons/Arrow.inline.svg';
import InfoIcon from '../../../assets/icons/Info.inline.svg';
import AnimatedCounter from '../../AnimatedCounter/AnimatedCounter';

import './HashtagsBox.scss';

const HashtagsBox: FC<{}> = () => {
    const { hashtags, currentCity } = useContext(AppContext);
    const [hashtagsData, setHastagsData] = useState<Hashtag[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (hashtags) {
            hashtags.sort((a, b) =>
                a.historialCount > b.historialCount ? -1 : b.historialCount > a.historialCount ? 1 : 0,
            );
            setHastagsData(hashtags.slice(0, 5));
        }

        return () => {};
    }, [hashtags, currentCity]);

    const handleHowWeScoreClick = () => {
        navigate('/how-we-score');
    };

    if (hashtagsData.length > 0) {
        return (
            <section className={`hashtags-box ${isExpanded ? 'hashtags-box--expanded' : ''}`}>
                <div className="hashtags-box__wrapper">
                    <div
                        className="hashtags-box__expand expand"
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        <span className="expand__label">Expand</span>
                        <ArrowIcon />
                    </div>
                    <div className="hashtags-box__content">
                        <p className="hashtags-box__title">
                            {`Who's getting `}
                            <br />
                            real right now?
                        </p>
                        <hr className="hashtags-box__line" />
                        <div className="hashtags-box__table table">
                            <div className="table__header">
                                <p className="table__column-label">HASHTAG</p>
                                <p className="table__column-label">MENTIONS</p>
                            </div>
                            <div className="table__body">
                                {hashtagsData.map((topic, index) => {
                                    return (
                                        <div key={`topic-${index}`} className="table__hashtag-item hashtag-item">
                                            <span className="hashtag-item__title">#{topic.name}</span>
                                            <span className="hashtag-item__count">
                                                <AnimatedCounter counter={topic.historialCount} />
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="table__how-we-score how-we-score" onClick={handleHowWeScoreClick}>
                                <InfoIcon />
                                <span className="how-we-score__label">How We Score</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return <></>;
};

export default HashtagsBox;
