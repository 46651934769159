import React from 'react';
import mapboxgl from 'mapbox-gl';

import { BACKGROUND_POINTS_CA } from '../json/data';

const backgroundCAPoints = (map: mapboxgl.Map) => {

    map.on('load', () => {

        map.addSource('mapPoints', {
            type: 'geojson',
            data: BACKGROUND_POINTS_CA,
        });

        map.addLayer({
            id: 'map-points',
            type: 'circle',
            source: 'mapPoints',
            maxzoom: 7,
            paint: {
                'circle-color': 'rgb(108,108,108)',
                'circle-radius': 3,
                'circle-opacity': 1,
            },
        });
    });
};

export default backgroundCAPoints;
