import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../store/AppContextProvider';
import {
    INITIAL_MAP_CONFIG_DESKTOP,
    INITIAL_MAP_CONFIG_MOBILE,
    MARKER_ZOOM_DESKTOP,
    MARKER_ZOOM_MOBILE,
} from '../../utils/constants/map';
import { isMobile } from '../../utils/isMobile';
import MapOverlay from './mapOverlay/MapOverlay';
import HashtagsBox from './hashtagsTable/HashtagsBox';
import cityMarkers from './layer/cityMarkers';
import backgroundCAPoints from './layer/bgPoints/backgroundCAPoints';
import backgroundCityPoints from './layer/bgPoints/backgroundCityPoints';
import cityLabels from './layer/cityLabels';
import SocialMediaBox from './socialMediaBox/SocialMediaBox';
import popup from './layer/popup/popup';

import mapboxgl from '!mapbox-gl';

import './Map.scss';

const Map = () => {
    const mapContainer = useRef(null);
    const map = useRef<mapboxgl.Map | null>(null);
    const { cities, pageContent, setIsSidebarOpen, setCurrentPost, setCurrentCity, clearCurrentPostAndCity } =
        useContext(AppContext);

    useEffect(() => {
        if (mapContainer.current && !!cities.length) {
            mapboxgl.accessToken =
                'pk.eyJ1IjoidW5pdGVkY29sbGVjdGl2ZSIsImEiOiJjbGVuZWY3ZmgxZTd2NDNxdnp6eGw2YzVkIn0.7z5s8gw5b97ZzntK1EJmNQ';
            map.current = new mapboxgl.Map({
                ...getConfig(),
                container: mapContainer.current, // container ID
            });

            // Layers
            backgroundCAPoints(map.current);
            backgroundCityPoints(map.current, cities);
            cityLabels(map.current, cities, openSidebarLabels, fly);
            cityMarkers(map.current, cities, openSidebarMarkers);
            popup(map.current, cities);
        }
        return () => {};
    }, [cities]);

    useEffect(() => {
        const { center, zoom } = getConfig();

        if (map.current) {
            map.current.boxZoom.enable();
            map.current.scrollZoom.enable();
        }
    }, [map.current]);

    const getConfig = () => {
        return isMobile() ? INITIAL_MAP_CONFIG_MOBILE : INITIAL_MAP_CONFIG_DESKTOP;
    };

    const fly = (long: number, lat: number, zoom: number = 9.5) => {
        if (mapContainer.current) {
            map.current?.flyTo({
                center: [long, lat],
                essential: true, // IMPORTANT: this animation is considered essential with respect to prefers-reduced-motion
                zoom: zoom,
            });
        }
    };

    const openSidebarMarkers = (feature: mapboxgl.MapboxGeoJSONFeature) => {
        const coordinates = feature.geometry.coordinates;
        const flyZoom = isMobile() ? MARKER_ZOOM_MOBILE : MARKER_ZOOM_DESKTOP;

        setCurrentPost(feature.properties?.id, feature.properties?.cityId);
        setCurrentCity(feature.properties?.cityId);
        setIsSidebarOpen(true);

        fly(coordinates[0], coordinates[1], flyZoom);
    };

    const openSidebarLabels = (currentCityId: string) => {
        setCurrentCity(currentCityId);
        setIsSidebarOpen(true);
    };

    map.current?.on('zoomend', () => {
        if (map.current && map.current?.getZoom() < 7) {
            setIsSidebarOpen(false);
        }
        if (map.current && map.current?.getZoom() < 6.7) {
            clearCurrentPostAndCity();
        } else {
            // setCitiesToShow(cities);
        }
    });

    return (
        <>
            <section className="map-slice">
                <div className="mapDiv mapDiv--visible">
                    <div className="map__map" id="map" ref={mapContainer} />
                    <MapOverlay fly={fly} />
                    <SocialMediaBox
                        title={pageContent?.socialMediaBox.body ?? ''}
                        instagram={pageContent?.socialMediaBox.instagramUrl ?? ''}
                        tiktok={pageContent?.socialMediaBox.tiktokUrl ?? ''}
                        twitter={pageContent?.socialMediaBox.twitterUrl ?? ''}
                    />
                    <HashtagsBox />
                </div>
            </section>
        </>
    );
};

export default Map;
