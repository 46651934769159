import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout/Layout';
import Map from '../components/heatmap/Map';

const MapPage: FC = () => {
    useEffect(() => {
        document.querySelector('html')?.classList.add('disable-scroll');
    }, []);

    return (
        <>
            <Helmet title="Get Real - Got Milk. | Map" />
            <Layout pageClassName="map-page" showMapsElements={true}>
                <Map />
            </Layout>
        </>
    );
};

export default MapPage;
